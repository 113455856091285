@use "@amzn/awsui-design-tokens/polaris.scss" as awsui;

.footer-wrapper {
  /*
   * Allows fixed footer to show on top of all other items
   * such as the popover div for copy to clipboard confirmation
   */
  /* Polaris requires sticky footer */
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  z-index: 3000; /* Polaris sets popover to 2000 */
}

.checkout-page-footer {
  display: block;
  margin: 0;
  color: awsui.$color-text-heading-secondary;
  padding: 20px 20px;
  background: awsui.$color-background-layout-main;
  box-shadow: 0 1px 2px 0 awsui.$color-border-divider-default,
    1px 1px 1px 0 awsui.$color-border-divider-default,
    -1px 1px 1px 0 awsui.$color-border-divider-default;
  box-shadow: 0 1px 2px 0
      var(--color-shadow-medium-3CtI4L, awsui.$color-border-divider-default),
    1px 1px 1px 0
      var(--color-shadow-side-2cOaCx, awsui.$color-border-divider-default),
    -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, awsui.$color-border-divider-default);

  nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
  }
}

.footer__actions {
  align-self: center;
}
